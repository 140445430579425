
<template>
  <div class="plan-list">
    <div class="row mb-4">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="my-0">
            Plans
          </h5>
          <b-button variant="primary" class="" @click.prevent="popupModalShow = true" type="button">
          <i class="ri ri-add-fill"></i> <span class="d-none d-sm-inline"> Add Plan</span></b-button>
        </div>
      </div>
    </div>
    <!-- <div class="row mb-4">
      <div class="col-12 text-center">
        <div class="d-inline-flex">
          <ul class="nav justify-content-center nav-pills rounded p-1"
              id="pills-tab"
              role="tablist">
              <li class="nav-item" role="presentation">
              <a
                  class="nav-link fw-semibold"
                  :class="{'active': billingPeriod == 'month'}"
                  href="javascript:void(0)"
                  role="tab"
                  :aria-selected="billingPeriod == 'month'"
                  @click="billingPeriod = 'month'" >
                  Monthly
              </a>
              </li>
              <li class="nav-item" role="presentation">
                  <a
                      class="nav-link fw-semibold"
                      :class="{'active': billingPeriod == 'year'}"
                      href="javascript:void(0)"
                      role="tab"
                      :aria-selected="billingPeriod == 'year'"
                      @click="billingPeriod = 'year'">
                      Yearly 
                  </a>
              </li>
          </ul>
        </div>
      </div>
    </div> -->
    <div class="row">
        <div v-for="(plan, tIndex) in planList" 
          :key="tIndex" class="col-sm-6 mb-3">
            <plan-card :plan="plan">
              <template v-slot:actions>
                <div class="d-flex border-top pt-3 mt-3">
                    <button @click="deletePlan(plan.id)" class="btn btn-sm me-3 btn-danger"> <i class="mdi mdi-trash-can-outline"></i> Delete</button>
                    <button @click="initEdit(plan)" class="btn btn-sm btn-primary"> <i class="mdi mdi-square-edit-outline"></i> Edit</button>
                </div>
              </template>
            </plan-card>
        </div>
    </div>
    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode? 'Update plan':'Add plan'"
      title-class="font-18" @hide="resetData()">
      <plan-form v-if="popupModalShow" @closeMe="resetData()"
       :editMode="editMode" :editItem="editItem" />
    </b-modal>
  </div>
</template>

<script>
import PlanForm from "@/components/forms/PlanForm.vue"
import PlanCard from "@/components/cards/PlanCard.vue"
export default {
  components:{
    PlanForm,
    PlanCard
  },
  data() {
    return {
      popupModalShow: false,
      editMode: false,
      editItem: null,
      billingPeriod:"month"
    }
  },
  computed: {
    planList(){
      return this.$store.state.plan.list.filter(p => p.billing_period == this.billingPeriod)
    },
  },
  methods: {
    deletePlan(itemId){
      this.confirmDelete({
        text: "You are about to delete this plan!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/plans/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$store.commit("DELETE_PLAN", itemId)
            }
          })
        }
      });
    },
    initEdit(item){
      this.editMode = true;
      this.editItem = {...item}
      this.popupModalShow = true  
    },
    resetData(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
    },
  },
  created(){
    this.$store.dispatch("plan/fetchPlans")
  }
}
</script>
