<template>
    <form autocomplete="off" class="">
        <div class="row g-3">
            <div class="col-md-6">
                <label for="inputTitle">
                    Title <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.title"
                    :class="{ 'is-invalid': v$.data.title.$error }"
                    id="inputTitle" name="title" class="form-control"
                    placeholder="Plan title" />
                <div v-for="(item, index) in v$.data.title.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label for="inputTitle">
                    Name <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.name"
                    :class="{ 'is-invalid': v$.data.name.$error }"
                    id="inputName" name="name" class="form-control"
                    placeholder="Plan name" />
                <div v-for="(item, index) in v$.data.name.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label for="inputPrice">
                    Price (per month) <span class="text-danger">*</span>
                </label>
                <input type="text" v-model="data.price" class="form-control"
                name="price" id="inputPrice" :class="{ 'is-invalid': v$.data.price.$error }" placeholder="Enter Price" />
                <div v-for="(item, index) in v$.data.price.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">Billing Period</label>
                <select class="form-select" v-model="data.billing_period"
                    name="billing_period" placeholder="--select billing period--" 
                    :class="{ 'is-invalid': v$.data.billing_period.$error}">
                    <option disabled value="">--select billing period--</option>
                    <option value="month">Monthly</option>
                    <!-- <option value="year">Yearly</option> -->
                </select>
                <div v-for="(item, index) in v$.data.billing_period.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-12">
                <label class="form-label">Type</label>
                <select class="form-select" v-model="data.type"
                    name="type" placeholder="--select type--" 
                    :class="{ 'is-invalid': v$.data.type.$error}">
                    <option disabled value="">--select type--</option>
                    <option value="device">Device</option>
                    <option value="everything">Everything</option>
                </select>
                <div v-for="(item, index) in v$.data.type.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label" for="description">Description</label>
                <textarea id="description"
                    v-model="data.description" class="form-control" rows="2"
                    placeholder="Write description" name="description"></textarea>
            </div>
            <div class="col-6 col-md-4">
                <b-form-checkbox v-model="data.is_active" name="is_active" switch>
                    Visibility
                </b-form-checkbox>
            </div>
            <div class="col-6 col-md-4">
                <b-form-checkbox v-model="data.is_popular" name="is_popular" switch>
                    Popular
                </b-form-checkbox>
            </div>            
            <div class="col-12">
                <label class="form-label">Features</label>
                <div class="input-group mb-3">
                    <input v-on:keyup.enter="addFeature()" v-model="newFeature" type="text" class="form-control" placeholder="Add feature" 
                    aria-label="add feature" aria-describedby="plusFeature">
                    <button @click="addFeature()" class="btn btn-primary" type="button" id="plusFeature"><i class="ri ri-add-fill"></i></button>
                </div>
                <div class="row g-2">
                    <div v-for="(feature, fIndex) in data.features" :key="fIndex"
                        class="mb-1 col-md-6 ">
                        <div class="d-flex px-2 py-1 bg-light align-items-center justify-content-between">
                            <span v-html="feature"></span>
                            <span @click="removeFeature(feature)" class="cursor-pointer text-danger ri ri-close-fill"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            newFeature: "",
            data: {
                title: "",
                name: "",
                price: "",
                type: "",
                billing_period: "",
                is_active: true,
                is_popular: false,
                description: "",
                features: []
            },
        }
    },
    validations() {
        return{
            data:{
                title: {
                    required: helpers.withMessage("Title is required", required),
                },
                name: {
                    required: helpers.withMessage("Name is required", required),
                },
                price: {
                    required: helpers.withMessage("Price is required", required),
                },
                type: {
                    required: helpers.withMessage("Plan type is required", required),
                },
                billing_period: {
                    required: helpers.withMessage("Select billing period", required),
                },
            }
        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/plans/create`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("plan/ADD_PLAN",response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
             this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/plans/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("plan/UPDATE_PLAN", response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        removeFeature(feature){
            const ItemIndex = this.data.features.findIndex((ft) => ft == feature)
            this.data.features.splice(ItemIndex, 1)
        },
        addFeature(){
            if(!this.newFeature) return
            this.data.features.push(this.newFeature);
            this.newFeature = ""
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>