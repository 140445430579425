<template>
     <div class="card mb-0 card-pricing ribbon-box right">
        <div v-if="plan.is_popular" class="ribbon-two ribbon-two-success"><span>Popular</span></div>
        <div class="card-body text-center">
            <h3 class="text-uppercase">{{plan.title}}</h3>
            <p class="">{{plan.description}}</p>
            <h2 class="mb-0">
                ${{plan.price}} 
                <small class="fs-13 text-muted text-capitalize">/{{plan.billing_period}}</small>
            </h2>
            <hr>
            <ul class="list-unstyled vstack text-start gap-2">
                <li v-for="(feature, fIndex) in plan.features" :key="fIndex">
                    <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                        <i class="ri-checkbox-circle-fill fs-15 align-middle"></i>
                        </div>
                        <div class="flex-grow-1" v-html="feature"></div>
                    </div>
                </li>
            </ul>
            <hr>
            <div class="d-sm-flex gap-3">
                <div class="form-check">
                    <input disabled type="checkbox" :checked="plan.is_active" class="form-check-input">
                    <label class="form-check-label" >Visibility</label>
                </div>
                <div class="form-check">
                    <input disabled type="checkbox" :checked="plan.is_popular" class="form-check-input">
                    <label class="form-check-label" >Popular</label>
                </div>
            </div>
            <slot name="actions"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        plan:{
            type:Object,
            required: true
        }
    }
}
</script>